import React, {useEffect} from 'react';
import appStore from '../../storage/AppStore'
import {observer} from 'mobx-react-lite'
import {get} from '../../common/requester'

const Settings = () => {
  const {user, tr, languages, user_types} = appStore
  
  useEffect(() => {
    getLanguages()
    getUserTypes()
  }, [])
  
  const getLanguages = async () => {
    const ls = await get('languages', {})
    if (ls) {
      appStore.setLanguages(ls.languages)
    }
  }
  
  const getUserTypes = async () => {
    const ut = await get('user_types', {})
    if (ut) {
      appStore.setUserTypes(ut.user_types)
    }
  }
  
  const onLanguageSelected = async (lang) => {
    const ls = await get('language', {'id': lang})
    if (ls) {
      appStore.initUser(ls)
      const tr = await get('translations', {'language': user.language})
        if (tr) {
          appStore.setTranslations(tr.t)
        }
    }
  }
  
  const onTypeChanged = async (user_type) => {
    if (user_type !== user.user_type) {
      const ut = await get('user_type', {'id': user_type})
      if (ut) {
        appStore.initUser(ut)
      }
    }
  }
  
  return (
    <>
      {/*<Section header={tr.user_type}>*/}
      {/*  <List>*/}
      {/*    {user_types.map(ut => (*/}
      {/*          <Cell*/}
      {/*              key={ut.id}*/}
      {/*              onClick={() => onTypeChanged(ut.id)}*/}
      {/*              after={<Switch defaultChecked={ut.id === user.user_type}/>}*/}
      {/*          >*/}
      {/*            {ut.icon} {ut.label}*/}
      {/*          </Cell>*/}
      {/*        ))}*/}
      {/*  </List>*/}
      {/*</Section>*/}
      {/*<Section header={tr.language}>*/}
      {/*  <List>*/}
      {/*    {languages.map(l => (*/}
      {/*      <Cell*/}
      {/*        key={l.id}*/}
      {/*        after={<Switch*/}
      {/*          defaultChecked={user.language === l.id}*/}
      {/*        />}*/}
      {/*        onClick={() => onLanguageSelected(l.id)}*/}
      {/*      >*/}
      {/*        {l.icon} {l.label}*/}
      {/*      </Cell>*/}
      {/*    ))}*/}
      {/*  </List>*/}
      {/*</Section>*/}
    </>
  );
}

export default observer(Settings);