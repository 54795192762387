const tg = window.Telegram.WebApp;

export function useTelegram() {
    const onClose = () => {
        tg.close()
    }
    const onToggleTheme = () => {
        if (tg.themeParams) {
          document.body.style.backgroundColor = tg.themeParams.bg_color || '#ffffff';
          document.body.style.color = tg.themeParams.text_color || '#000000';
        }
    }
    const onToggleButton = () => {
        if(tg.MainButton.isVisible) {
            tg.MainButton.hide()
        } else {
            tg.MainButton.show()
        }
    }
    
    const isValid = () => {
        const t_id = tg.initDataUnsafe?.user?.id
        return !!t_id;
    }

    const isVersionValid = () => {
        const ver = tg.version
        return ver && ver >= 6.9
    }
    
    return {
        onClose,
        onToggleButton,
        onToggleTheme,
        tg,
        user: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,
        isValid,
        isVersionValid,
    }
}