import * as React from 'react'
import {observer} from 'mobx-react-lite'
import { ToastContainer, toast } from 'react-toastify';
import systemStore from '../../storage/SystemStore'
import {useEffect, useRef} from 'react'
import 'react-toastify/dist/ReactToastify.css';

const Notification = () => {
  
  useEffect(() => {
    if (systemStore.message) {
      toast(systemStore.message);
      systemStore.clearMessage()
    }
  }, [systemStore.message])
 
  return (
    <ToastContainer />
  )
}

export default observer(Notification)
