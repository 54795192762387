import React, {useEffect} from 'react';
import {re_only_numbers} from '../../common/utils'
import {useTelegram} from '../../hooks/useTelegram'
import { useNavigate } from "react-router-dom";
import appStore from '../../storage/AppStore'
import serviceStore from '../../storage/ServiceStore'
import {observer} from 'mobx-react-lite'

const Service = () => {
  const {tg} = useTelegram()
  const navigate = useNavigate();
  const {tr} = appStore;
  const {service, companies, service_types, service_entities, included_options} = serviceStore;
  
  
  const onPriceChanged = (event) => {
    if (re_only_numbers.test(event.target.value)) {
      serviceStore.setPrice(event.target.value);
    } else {
      serviceStore.setPrice('');
    }
  }
  const onAddService = async () => {
    serviceStore.addService().then(s => navigate(`/service/edit/${s.id}`)).catch(err => tg.showAlert(tr.check_inputs))
  }
  return (
    <>Service</>
    // <Section header={tr.add}>
    //   <List style={{
    //     background: 'var(--tgui--secondary_bg_color)'
    //   }}>
    //       <Textarea header={tr.description}
    //                 placeholder={tr.description_placeholder}
    //                 value={service.name}
    //                 onInput={(e) => {serviceStore.setName(e.target.value)}} />
    //       <Select header={tr.company} value={service.company_id} onChange={(e) => serviceStore.setCompany(e.target.value)}>
    //                 {companies.map(l => (
    //                     <option key={l.id} value={l.id}>{l.name}</option>
    //                 ))}
    //       </Select>
    //       <Select header={tr.service_type} value={service.service_type_id} onChange={(e) => serviceStore.setServiceType(e.target.value) }>
    //                 {service_types.map(l => (
    //                     <option key={l.id} value={l.id}>{l.icon} {l.name}</option>
    //                 ))}
    //       </Select>
    //       <Select header={tr.service_entity} value={service.service_entity_id} onChange={(e) => serviceStore.setServiceEntity(e.target.value)}>
    //                 {service_entities.map(l => (
    //                     <option key={l.id} value={l.id}>{l.icon} {l.name}</option>
    //                 ))}
    //       </Select>
    //       <Multiselect
    //           header={tr.service_includes}
    //           options={included_options.map(io => {return {value: io.id, label: `${io.icon} ${io.name}`}})}
    //           value={service.included}
    //           onChange={(e) => serviceStore.setIncluded(e)}
    //       />
    //     <Input
    //       header={tr.price}
    //       value={service.price}
    //       onChange={onPriceChanged}
    //       after={<span>{service.currency?.name}</span>}
    //     />
    //     <Cell>
    //       <Button
    //         mode="filled"
    //         size="l"
    //         disabled={!serviceStore.isValid}
    //         onClick={onAddService}
    //       >
    //         {tr.add}
    //       </Button>
    //     </Cell>
    //     </List>
    //
    // </Section>
  );
};

export default observer(Service);