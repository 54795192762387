import {action, computed, makeObservable, observable} from 'mobx';
import BaseModel from './BaseModel';

export default class User extends BaseModel {

  constructor(store) {
    super(store);
    
     makeObservable(this, {
       id: observable,
       telegram_id: observable,
       fio: observable,
       first_name: observable,
       last_name: observable,
       username: observable,
       user_type: observable,
       language: observable,
       country_id: observable,
       region_id: observable,
       district_id: observable,
       lat: observable,
       lon: observable,
       fromJson: action,
       setCountry: action,
       setLanguage: action,
       setUserType: action
     });
  }

  id = 0;
  telegram_id = '';
  fio = '';
  first_name = '';
  last_name = '';
  username = '';
  user_type = 'c';
  language = 'ru';
  country_id = '';
  region_id = '';
  district_id = '';
  country = {};
  region = {};
  district = {};
  lat = 0;
  lon = 0;
 
  

  fromJson(data) {
    super.fromJson(data);
  }

  async setCountry(country_id) {
    this.country_id = country_id
  }

  async setLanguage(language) {
    this.language = language
  }

  async setUserType(user_type) {
    this.user_type = user_type
    // if (this.id) {
    //   await get('language', {'language': language})
    // }
  }
}
