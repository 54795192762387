import {useTelegram} from './hooks/useTelegram'
import {get} from './common/requester'
import Header from './components/Header/Header'
import {Route, Routes} from 'react-router-dom'
import Supplier from './components/Supplier/Supplier'
import Client from './components/Client/Client'
import Companies from './components/Companies/Companies'
import AddService from './components/Services/AddService'
import EditService from './components/Services/EditService'
import Services from './components/Services/Services'
import ViewService from './components/Services/ViewService'
import Settings from './components/Settings/Settings'
import {observer} from 'mobx-react-lite'
import appStore from './storage/AppStore'
import {useEffect} from 'react'
import Notification from './components/Notification/Notification'
import Loading from './components/Loading/Loading'
import Footer from "./components/Footer/Footer";
import Container from "react-bootstrap/Container";


function App() {
    const {tg, isValid, onToggleTheme} = useTelegram();
    
    const init = async () => {
        const res = await get('init', {})
        if (res) {
            appStore.initUser(res.user)
            appStore.setLanguages(res.languages)
            appStore.setTranslations(res.translations)
            appStore.setCountries(res.countries)
            appStore.setUserTypes(res.user_types)
        }
    }
    
    useEffect(() => {
        tg?.ready();
        init();
        if (isValid()){
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                if (window.history.state.idx === 0) {
                    tg?.close()
                } else {
                    window.history.back()
                }
            })
            tg.onEvent('themeChanged', onToggleTheme);
            return () => tg.offEvent('themeChanged', onToggleTheme);
        }
    }, [tg]);
    
    return (
      <>
          <Loading/>
          <Header/>
          {appStore.user && appStore.user.id && appStore.user.user_type === 's' ?
            <Routes>
                <Route index element={<Supplier/>}/>
                <Route path={'companies'} element={<Companies/>}/>
                <Route path={'service/add'} element={<AddService/>}/>
                <Route path={'service/edit/:id'} element={<EditService/>}/>
                <Route path={'service/view/:id'} element={<ViewService/>}/>
                <Route path={'services'} element={<Services/>}/>
                <Route path={'settings'} element={<Settings/>}/>
            </Routes> :
            <Routes>
                <Route index element={<Client/>}/>
            </Routes>
          }
          {/* Footer */}
          <footer className="bg-dark text-white text-center py-3 mt-4">
              <Container>
                  <p>&copy; 2024 ToyPoy. All rights reserved.</p>
                  <p>
                      <a href="#" className="text-white me-3">Privacy Policy</a>
                      <a href="#" className="text-white">Terms of Service</a>
                  </p>
              </Container>
          </footer>
          <Footer/>
          <Notification/>
      </>
    );
}

export default observer(App);
