import * as React from 'react'
import {observer} from 'mobx-react-lite'
import systemStore from '../../storage/SystemStore'
import './Loading.css'
import Spinner from 'react-bootstrap/Spinner'

const Loading = () => {
  if (!systemStore.loading) return null
  return (
    <div className={'loading'}>
      <div className={'loading_content'}>
        <Spinner variant={'danger'}/>
      </div>
    </div>
  )
}
export default observer(Loading)