import React, {useEffect} from 'react';
import systemStore from "../../storage/SystemStore";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import appStore from "../../storage/AppStore";
import {observer} from "mobx-react-lite";
import {Button, ButtonGroup, ButtonToolbar} from "react-bootstrap";
import {botUsername} from "../../common/config";

const Footer = () => {
    const setUserType = (userType) => {
        if (userType ==='s' && !appStore.user.id) {
            window.location.href = `https://t.me/${botUsername()}?start=-start`;
            return false
        }
        appStore.setUserType(userType);
    }
    return (
        <Navbar bg={systemStore.mode} variant="secondary" expand="md" sticky={"bottom"}>
            <Container className={"d-flex justify-content-between align-items-center"} style={{width: "280px"}}>
                <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup className="me-2" aria-label="First group">
                        <Button onClick={() => {setUserType('s')}}
                            variant={appStore.userType === 's' ? 'success' : 'secondary'}>{appStore.tr.supplier}</Button>
                        <Button onClick={() => {setUserType('c')}}
                            variant={appStore.userType === 'c' ? 'success' : 'secondary'}>{appStore.tr.client}</Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Container>
        </Navbar>
    )
}

export default observer(Footer);