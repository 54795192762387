import React, {useEffect} from 'react';
import {AiFillMoon, AiFillSetting, AiOutlineSun} from 'react-icons/ai';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../../images/logo1.webp'
import {observer} from "mobx-react-lite";
import './Header.css'
import systemStore from "../../storage/SystemStore"
import {Link} from 'react-router-dom'
import appStore from "../../storage/AppStore";

const Header = () => {
    useEffect(()=>{
      document.documentElement.setAttribute('data-bs-theme', systemStore.mode)
    },[systemStore.mode])
    return (
      <Navbar bg={systemStore.mode} variant="secondary" expand="md" sticky={"top"}>
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="ToyPoy" height={36}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/">home</Nav.Link>

                {appStore.userLanguage &&
                    <NavDropdown title={appStore.userLanguage?.icon + " " + appStore.userLanguage?.label} id="language-dropdown">
                        {appStore.languages.map((lang, index) => (
                            <NavDropdown.Item onClick={() => {appStore.setLanguage(lang.id)}} key={index}>{lang.icon} {lang.label}</NavDropdown.Item>
                        ))}
                    </NavDropdown>
                }
                {appStore.userCountry &&
                    <NavDropdown title={appStore.userCountry?.name} id="country-dropdown">
                        {appStore.countries.map((country, index) => (
                            <NavDropdown.Item onClick={() => {appStore.setCountry(country.id)}} key={country.id}>{country.name}</NavDropdown.Item>
                        ))}
                    </NavDropdown>
                }
                <Nav.Link as={Link} to="/settings"><AiFillSetting size={18}/> Settings</Nav.Link>
              <Nav.Link onClick={() => systemStore.toggleMode()}>{systemStore.mode === 'dark' ? <AiOutlineSun size={20} /> : <AiFillMoon size={20} />
}</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
};

export default observer(Header);