import {action, autorun, computed, makeObservable, observable} from 'mobx'
import User from '../models/User'
import {get} from "../common/requester";

class AppStore {
  jwt = '';
  user = new User();
  countries = []
  languages = []
  user_types = []
  tr = {}
  rand = 0
  
  constructor() {
    makeObservable(this, {
      jwt: observable,
      user: observable,
      countries: observable,
      languages: observable,
      user_types: observable,
      tr: observable,
      rand: observable,
      setJwt: action,
      initUser: action,
      setLanguages: action,
      setTranslations: action,
      setUserTypes: action,
      setCountries: action,
      setCountry: action,
      userCountry: computed,
      setLanguage: action,
      setUserType: action,
      userType: computed,
      t: action,
    });
  }

  
  setJwt(token) {
    this.jwt = token
  }
  
  initUser(json) {
    this.user.fromJson(json)
  }

  setCountries(data) {
    sessionStorage.setItem('countries', JSON.stringify(data))
    this.countries = data
  }

  async setCountry(country_id) {
    await this.user.setCountry(country_id)
    if (this.user.id) {
      await get('country', {'country_id': country_id})
    }
  }

  get
  userCountry() {
    return this.countries.find(c => c.id === this.user.country_id)
  }

  async setLanguage(language) {
    await this.user.setLanguage(language)
    sessionStorage.setItem('language', language)
    if (this.user.id) {
      await get('language', {'language': language})
    }
    const response = await get('translations', {language: language})
    this.setTranslations(response.t)
    const response2 = await get('countries')
    this.setCountries(response2.countries)
  }

  setLanguages(data) {
    sessionStorage.setItem('languages', JSON.stringify(data))
    this.languages = data
  }

  get
  userLanguage() {
    return this.languages.find(l => l.id === this.user.language)
  }
  
  setTranslations(data) {
    sessionStorage.setItem('translations', JSON.stringify(data))
    this.tr = data
  }

  t(key) {
    return this.tr[key] || key
  }
  
  setUserTypes(data) {
    this.user_types = data
  }

  async setUserType(user_type) {
    await this.user.setUserType(user_type)
  }

  get userType() {
    return this.user_types.find(t => t.id === this.user.user_type)?.id
  }
}
const appStore = new AppStore();
export default appStore;