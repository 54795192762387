import React, {useEffect, useState} from 'react';
import Supplier from "../Supplier/Supplier";
import { useNavigate } from "react-router-dom";
import serviceStore from '../../storage/ServiceStore'
import appStore from '../../storage/AppStore'
import {observer} from 'mobx-react-lite'

const Services = () => {
  const navigate = useNavigate()
  const {tr} = appStore
  
  useEffect(() => {
    if (appStore.user) {
      serviceStore.getServices()
    }
  }, [appStore.user])
  
  
  return (
    <>
      <Supplier/>
      {/*<List style={{*/}
      {/*  background: 'var(--tgui--secondary_bg_color)'*/}
      {/*}}>*/}
      {/*  <Section header={tr.my_services}>*/}
      {/*    {serviceStore.services.map((s, index) => (*/}
      {/*      <Cell key={s.id}*/}
      {/*            before={index + 1}*/}
      {/*            subhead={s.company?.name}*/}
      {/*            onClick={() => {*/}
      {/*              navigate(`/service/view/${s.id}`)*/}
      {/*            }}*/}
      {/*      >*/}
      {/*        {s.service_entity?.icon} {s.service_entity?.name}, {s.service_type?.icon} {s.service_type?.name}*/}
      {/*      </Cell>*/}
      {/*    ))}*/}
      {/*  </Section>*/}
      {/*</List>*/}
    </>
  );
}

export default observer(Services);