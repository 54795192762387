import React, {useEffect, useState} from 'react';
import {get} from '../../common/requester'
import appStore from '../../storage/AppStore'

const Companies = () => {
  
  useEffect(() => {
    if(appStore.user) {
      getCompanies()
    }
  }, [appStore.user])
  
  const getCompanies = async () => {
    const mc = await get('companies', {})
    if(mc) {
      appStore.setCompanies(mc.companies)
    }
  }
  
  return (
    <>companies</>
    // <Section header={appStore.tr.my_companies}>
    //     <List>
    //       {appStore.companies.map(c => (
    //       <Cell>{c.name}</Cell>
    //       ))}
    //     </List>
    // </Section>
  );
};

export default Companies;