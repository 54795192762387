import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import Supplier from "../Supplier/Supplier";
import serviceStore from '../../storage/ServiceStore'
import appStore from '../../storage/AppStore'
import {observer} from 'mobx-react-lite'
import {useTelegram} from '../../hooks/useTelegram'
import {getBaseUrl} from '../../common/config'

const ViewService = () => {
    const navigate = useNavigate();
    const params = useParams()
    const {service} = serviceStore
    const {tr, user} = appStore
    const {tg} = useTelegram()

    useEffect(() => {
        if (user) {
            serviceStore.getServiceById(params.id)
        }
    }, [user])
    
    const onUploadImages = () => {
        serviceStore.uploadImages().then(tg.close())
    }
    
    const IncludedEntities = () => {
        return (
            <InlineButtons mode={"plain"}>
                {service.included?.map(se => (
                    <InlineButtons.Item key={se.id} text={se.name}>
                        {se.icon}
                    </InlineButtons.Item>
                ))}
            </InlineButtons>
        )
    }
    
    const ServiceImage = ({img}) => {
        return (
          <Image
            style={{marginLeft: '10px', marginRight: '10px'}}
            size={96}
            src={`${getBaseUrl()}/image?i=${img.path_md}`}
          />
        )
    }
    return (
        <>
            <Supplier/>
            {/*<Section header={`${tr.service_entity} #${service.id}`}>*/}
            {/*    <List>*/}
            {/*        <Cell*/}
            {/*            before={`${service.price} ${service.currency?.name}`}*/}
            {/*            subhead={tr.description}*/}
            {/*            subtitle={service.company?.name}*/}
            {/*        >*/}
            {/*            {service.name}*/}
            {/*        </Cell>*/}
            {/*        <Cell*/}
            {/*            before={`${service.service_entity?.icon} ${service.service_entity?.name}`}*/}
            {/*            subhead={`${service.service_type?.icon} ${service.service_type?.name}`}*/}
            {/*        >*/}
            {/*            <IncludedEntities/>*/}
            {/*        </Cell>*/}
            {/*        <Cell subhead={service.country?.name} subtitle={service.region?.name}>*/}
            
            {/*        </Cell>*/}
            {/*        <Cell*/}
            {/*          subhead={tr.media}*/}
            {/*          after={<Button onClick={onUploadImages}>{tr.upload}</Button>}*/}
            {/*        >*/}
            {/*        */}
            {/*        </Cell>*/}
            {/*        <div style={{*/}
            {/*                width: '100%',*/}
            {/*                display: 'flex',*/}
            {/*                flexDirection: 'row',*/}
            {/*                flexWrap: 'wrap',*/}
            {/*                justifyContent: 'center',*/}
            {/*                marginBottom: '10px',*/}
            {/*                padding: '10px'*/}
            {/*            }}>*/}
            {/*                {service.data.images?.map((img, i) => <ServiceImage key={i} img={img}/>)}*/}
            {/*            </div>*/}
            {/*    </List>*/}
            {/*</Section>*/}
        </>
    );
}

export default observer(ViewService);