import React from 'react';
import {Card, Carousel, Col, Container, Row} from 'react-bootstrap'

const Client = () => {
  return (
    <Container className="mt-4">
        <Row>
          <Col md={4} className="mb-4">
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Post Title</Card.Title>
                <Card.Text>
                  This is a short description of the post. Add hashtags or relevant info here.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Post Title</Card.Title>
                <Card.Text>
                  This is a short description of the post. Add hashtags or relevant info here.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Post Title</Card.Title>
                <Card.Text>
                  This is a short description of the post. Add hashtags or relevant info here.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://via.placeholder.com/300"
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
              <Card.Body>
                <Card.Title>Post Title</Card.Title>
                <Card.Text>
                  This is a short description of the post. Add hashtags or relevant info here.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          {/* You can duplicate the above <Col> for more photo sliders */}

        </Row>
      </Container>
  );
};

export default Client;